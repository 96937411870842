import { MouseEventHandler, ReactNode } from "react";

export interface Props {
	color: "transparent" | "blue";
	size: "auto" | "full";
	onClick?: MouseEventHandler<HTMLButtonElement>;
	disabled?: boolean;
	children?: ReactNode;
}

const Button = ({ onClick, disabled, children, color, size }: Props) => {
	const colors = {
		transparent:
			"bg-transparent disabled:opacity-50 hover:bg-blue hover:text-white",
		blue: "bg-blue text-white",
	};

	const sizes = {
		full: "w-full",
		auto: "w-auto",
	};

	return (
		<button
			type="button"
			className={`${colors[color]} ${
				sizes[size]
			} px-8 py-3 border-2 border-blue rounded font-bold flex items-center justify-center ${
				disabled ? "cursor-default opacity-30" : "cursor-pointer"
			}`}
			onClick={onClick}
			disabled={disabled}
		>
			{children}
		</button>
	);
};

export default Button;
