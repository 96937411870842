import logo from "assets/images/logo.png";
import { ReactNode } from "react";

export interface Props {
	children: ReactNode;
}

export const LandingPages = ({ children }: Props) => {
	return (
		<div className="bg-cream-200 flex flex-col justify-center items-center h-screen">
			<img src={logo} alt="logo" />
			<div className="my-8">Vanguard EDI 2.0</div>
			<div className="w-144">{children}</div>
		</div>
	);
};
