import { CheckBox } from "components/atoms/CheckBox";
import { ExpandButton } from "components/atoms/ExpandButton";
import { StatusBox } from "components/atoms/StatusBox";
import { Invoice } from "graphql/types";
import { useState } from "react";
import { ReactSVG } from "react-svg";
import parse from "html-react-parser";
import copy from "assets/icons/copy.svg";
import AusPostLogo from "assets/icons/AusPost.svg";
import { InvoiceModal } from "../InvoiceModal";

export interface Props {
	invoice: Invoice;
	selected: boolean;
	onSelect(): void;
}

export const TableRow = ({ invoice, selected, onSelect }: Props) => {
	const onExpandClick = () => {
		if (invoice.invalidFields.length > 0) {
			setRowExpanded(!rowExpanded);
		} else {
			setModalVisible(true);
		}
	};
	const onCopy = () => {
		navigator.clipboard.writeText(invoice.invoiceNumber);
	};
	const [modalVisible, setModalVisible] = useState(false);
	const [rowExpanded, setRowExpanded] = useState(false);

	return (
		<div
			className={`${
				rowExpanded ? "bg-cream-300" : "bg-white"
			} grid grid-cols-table items-center auto-rows-3.5em mx-8 border-b border-cream-600 last:border-b-0`}
		>
			<div className="col-span-8 h-1px" />
			<InvoiceModal
				visible={modalVisible}
				invoice={invoice}
				closeModal={() => setModalVisible(false)}
			/>
			<button
				className="flex justify-center items-center h-14 w-full"
				onClick={onCopy}
				type="button"
			>
				<ReactSVG src={copy} />
			</button>
			<div>
				<label htmlFor={invoice.myobId} className="flex">
					<CheckBox
						checked={selected}
						onChange={onSelect}
						disabled={invoice.status !== "Unprocessed"}
					/>
					<span className="ml-4">{invoice.invoiceNumber}</span>
				</label>
			</div>
			<div>{invoice.date.substr(0, 10)}</div>
			<div>{invoice.name}</div>
			<div>{invoice.postcode}</div>
			<div>
				<span className="mr-4">{invoice.shippingMethod}</span>
				<span className={`icon-${invoice.distributionNetwork} dn-icon`}>
					{invoice.distributionNetwork === "AusPost" ? (
						<ReactSVG
							src={AusPostLogo}
							wrapper="span"
							beforeInjection={(svg) => {
								svg.setAttribute("style", "display: inline");
							}}
						/>
					) : (
						""
					)}
				</span>
			</div>
			<div>
				<StatusBox status={invoice.status} />
			</div>
			<div className="flex justify-center mr-8 w-full">
				<ExpandButton onClick={onExpandClick} plus />
			</div>
			<div
				className={`${
					rowExpanded ? "grid" : "hidden"
				} grid-cols-sub-table items-center col-span-8 bg-cream-200 px-5 py-4`}
			>
				<div className="font-bold text-sm pl-4 h-12 flex items-center">
					Field
				</div>
				<div className="font-bold text-sm">Value</div>
				<div className="font-bold text-sm text-center">Count/Limit</div>
				<div className="font-bold text-sm text-center">Reason</div>
				<div className="col-span-4 border-b border-cream-600 last:border-b-0 h-1px" />
				{invoice.invalidFields.map((field) => (
					<>
						<div className="pl-4 text-sm h-12 flex items-center">
							{field.field}
						</div>
						<div className="overflow-x-auto text-sm">{parse(field.value)}</div>
						<div className="text-center text-sm">
							{field.value.length}/{field.limit}
						</div>
						<div className="text-center text-sm">{field.reason}</div>
					</>
				))}
				<div className="col-span-4 border-b border-cream-600 last:border-b-0 h-1px" />
			</div>
		</div>
	);
};

// const SubTableRow = () => {};
