export interface Props {
	value: string;
	onChange(date: string): void;
	className?: string;
}

export const DateSelect = ({ value, onChange, className }: Props) => {
	return (
		<input
			className={`border border-cream-600 w-52 px-5 focus-within:border-cream-900 outline-none ${className}`}
			type="date"
			value={value}
			onChange={(event) => onChange(event.target.value)}
		/>
	);
};
