import { ReactSVG } from "react-svg";
import processed from "assets/icons/processed.svg";
import unprocessed from "assets/icons/unprocessed.svg";
import error from "assets/icons/error.svg";
import warning from "assets/icons/warning.svg";
import processing from "assets/icons/processing.svg";

export interface Props {
	status: string;
}

export const StatusBox = ({ status }: Props) => {
	let svgSrc = "";
	switch (status) {
		case "Processed":
			svgSrc = processed;
			break;
		case "Unprocessed":
			svgSrc = unprocessed;
			break;
		case "Error":
			svgSrc = error;
			break;
		case "Warning":
			svgSrc = warning;
			break;
		case "Processing":
			svgSrc = processing;
			break;
	}
	return (
		<div className="flex bg-cream-200 border border-cream-600 rounded p-2 justify-center">
			<div className="mr-2">
				<ReactSVG src={svgSrc} />
			</div>
			<div>{status}</div>
		</div>
	);
};
