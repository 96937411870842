/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Button from "components/atoms/Button";
import { ModalInvoiceStep } from "components/molecules/ModalInvoiceStep";
import {
	Invoice,
	useClearInvoiceMutation,
	useResolveInvoiceMutation,
} from "graphql/types";
import { ReactSVG } from "react-svg";
import { gql } from "@apollo/client";
import { useState } from "react";

import x from "assets/icons/x.svg";
import processing from "assets/icons/processing.svg";

const _RESOLVE_INVOICE_MUTATION = gql`
	mutation ResolveInvoice($invoiceId: String!) {
		resolveInvoice(invoiceId: $invoiceId)
	}
`;

const _CLEAR_INVOICE_MUTATION = gql`
	mutation ClearInvoice($invoiceId: String!) {
		clearInvoice(invoiceId: $invoiceId)
	}
`;

export interface Props {
	invoice: Invoice;
	visible: boolean;
	closeModal(): void;
}

export const InvoiceModal = ({ closeModal, visible, invoice }: Props) => {
	const onClick = async () => {
		setResolving(true);
		switch (invoice.status) {
			case "Error":
				await clearInvoice({
					variables: {
						invoiceId: invoice.myobId,
					},
				});
				break;
			case "Warning":
				await resolveInvoice({
					variables: {
						invoiceId: invoice.myobId,
					},
				});
				break;
		}
		setResolving(false);
	};

	const [resolving, setResolving] = useState(false);
	const [resolveInvoice] = useResolveInvoiceMutation();
	const [clearInvoice] = useClearInvoiceMutation();

	const stepDivs = invoice.processingSteps.map((step) => (
		<ModalInvoiceStep key={step.name} text={step.name} status={step.status} />
	));

	let disabled = true;
	let buttonText = <>{invoice.status}</>;
	if (invoice.status === "Warning") {
		disabled = false;
		buttonText = <>Resolve</>;
	}
	if (invoice.status === "Error") {
		disabled = false;
		buttonText = <>Clear</>;
	}

	if (resolving) {
		disabled = true;
		buttonText = <ReactSVG src={processing} />;
	}

	return (
		<div
			className={`${
				visible ? "flex" : "hidden"
			} fixed h-screen w-screen top-0 left-0 bg-black bg-opacity-20 justify-center`}
		>
			<div className="w-116 h-128 bg-white top-36 relative flex flex-col justify-center items-center px-20">
				<ReactSVG
					onClick={closeModal}
					src={x}
					className="absolute top-6 right-6"
				/>
				<div className="mb-9">{`Invoice ${invoice.invoiceNumber}`}</div>
				{stepDivs}
				<Button
					size="full"
					color="transparent"
					disabled={disabled}
					onClick={onClick}
				>
					{buttonText}
				</Button>
			</div>
		</div>
	);
};
