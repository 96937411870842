import { ReactSVG } from "react-svg";
import plusSvg from "assets/icons/plus.svg";
import minusSvg from "assets/icons/minus.svg";

export interface Props {
	plus: boolean;
	onClick(): void;
}

export const ExpandButton = ({ plus, onClick }: Props) => {
	return (
		<button
			type="button"
			onClick={onClick}
			className="w-7 h-7 border-2 flex justify-center items-center rounded"
		>
			<ReactSVG src={plus ? plusSvg : minusSvg} />
		</button>
	);
};
