/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/control-has-associated-label */

import { ReactSVG } from "react-svg";
import checkmark from "assets/icons/white-check-mark.svg";

export interface Props {
	checked: boolean;
	onChange(): void;
	disabled?: boolean;
}

export const CheckBox = ({ checked, onChange, disabled }: Props) => {
	return (
		<div
			onClick={onChange}
			className={`w-6 h-6 flex justify-center items-center
			${disabled ? "opacity-0" : ""}
			${checked ? "bg-success" : "bg-white border-cream-600 border"}`}
		>
			{checked && <ReactSVG src={checkmark} className="fill-white" />}
		</div>
	);
};
