import Button from "components/atoms/Button";
import { LandingPages } from "components/templates/LandingPages";
import { ReactSVG } from "react-svg";
import chevronRight from "assets/icons/chevron-right.svg";

const baseHost = process.env.REACT_APP_BASE_HOST;

export interface Props {}

const Index = ({}: Props) => {
	return (
		<LandingPages>
			<a
				className="w-full"
				href={`https://secure.myob.com/oauth2/account/authorize?client_id=79pjj8yy8w8ct4ywvvntvrd5&redirect_uri=https%3A%2F%2F${baseHost}%2FOauthCallback%2F&response_type=code&scope=CompanyFile`}
			>
				<Button size="full" color="blue">
					Link with MYOB AccoudntRight Live{" "}
					<ReactSVG className="ml-8" src={chevronRight} />
				</Button>
			</a>
		</LandingPages>
	);
};

export default Index;
