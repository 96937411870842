import Button from "components/atoms/Button";
import { DateSelect } from "components/atoms/DateSelect";
import { Search } from "components/atoms/Search";
import { Choice, Select } from "components/atoms/Select";
import { MouseEventHandler } from "react";

const choices: Choice<string>[] = [
	{
		label: "All Status",
		value: "All",
	},
	{
		label: "Processed",
		value: "Processed",
	},
	{
		label: "Warning",
		value: "Warning",
	},
	{
		label: "Error",
		value: "Error",
	},
	{
		label: "Unprocessed",
		value: "Unprocessed",
	},
];

export interface Props {
	textFilter: string;
	statusFilter: string;
	processEnabled: boolean;
	refreshEnabled: boolean;
	fromDate: string;
	toDate: string;
	onRefresh: MouseEventHandler<HTMLButtonElement>;
	onProcess: MouseEventHandler<HTMLButtonElement>;
	onFromDateChange(date: string): void;
	onToDateChange(date: string): void;
	onTextFilterChange(filter: string): void;
	onStatusFilterChange(filter: string): void;
}

export const TableFilterBar = ({
	textFilter,
	statusFilter,
	processEnabled,
	refreshEnabled,
	fromDate,
	toDate,
	onRefresh,
	onProcess,
	onToDateChange,
	onFromDateChange,
	onTextFilterChange,
	onStatusFilterChange,
}: Props) => {
	return (
		<div className="flex justify-between gap-2">
			<Search
				value={textFilter}
				onChange={onTextFilterChange}
				classNames="w-64"
			/>
			<Select
				onChange={(event) => onStatusFilterChange(event.currentTarget.value)}
				choices={choices}
				value={statusFilter}
				classNames="w-52"
			/>
			<DateSelect value={fromDate} onChange={onFromDateChange} />
			<DateSelect value={toDate} onChange={onToDateChange} />
			<Button
				size="auto"
				color="transparent"
				onClick={onRefresh}
				disabled={!refreshEnabled}
			>
				Refresh
			</Button>
			<Button
				size="auto"
				color="transparent"
				onClick={onProcess}
				disabled={!processEnabled}
			>
				Process
			</Button>
		</div>
	);
};
