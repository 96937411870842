import Button from "components/atoms/Button";
import { Divider } from "components/atoms/Divider";
import { SelectWithLabel } from "components/molecules/SelectWithLabel";
import { TextFieldWithLabel } from "components/molecules/TextFieldWithLabel";
import { LandingPages } from "components/templates/LandingPages";
import {
	CompanyFile,
	useGetCompanyFilesQuery,
	useLoginCompanyFileMutation,
} from "graphql/types";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

export interface Props {}

export const CompanyFiles = ({}: Props) => {
	const onSignIn = async () => {
		const values = methods.getValues();
		const result = await login({
			variables: {
				cfId: companyFile?.id as string,
				username: values.username,
				password: values.password,
			},
		});

		if (result.data?.loginCompanyFile === true) {
			router.push("/invoices");
		}
	};

	const { data } = useGetCompanyFilesQuery();
	const [login] = useLoginCompanyFileMutation();
	const [companyFile, setCompanyFile] = useState<CompanyFile>();
	const router = useHistory();
	const methods = useForm();

	useEffect(() => {
		if (!data) {
			return;
		}

		setCompanyFile(data.companyFiles[0]);
	}, [data]);

	if (!companyFile) {
		return <LandingPages>loading</LandingPages>;
	}

	const companyFiles = data?.companyFiles as CompanyFile[];
	const companyFileChoices = companyFiles.map((file) => ({
		value: file.id,
		label: file.name,
	}));

	return (
		<FormProvider {...methods}>
			<LandingPages>
				<div className="bg-cream-300 p-10">
					<SelectWithLabel
						label="Select your company"
						choices={companyFileChoices}
						value={companyFile?.id as string}
						onChange={(e) => {
							const companyFileId = companyFiles.find(
								(file) => file.id === e.target.value
							);
							setCompanyFile(companyFileId);
						}}
					/>
				</div>
				<Divider />
				<div className="bg-cream-300 p-10 flex flex-col gap-8">
					<TextFieldWithLabel label="Username" name="username" />
					<TextFieldWithLabel label="Password" name="password" />
					<Button size="full" color="blue" onClick={onSignIn}>
						Sign in
					</Button>
				</div>
			</LandingPages>
		</FormProvider>
	);
};
