import { TableFilterBar } from "components/organisms/TableFilterBar";
import { TableHeader } from "components/organisms/TableHeader";
import { TableRow } from "components/organisms/TableRow";
import { BaseTemplate } from "components/templates/BaseTemplate";
import { gql } from "@apollo/client";
import { useProcessInvoicesMutation } from "graphql/types";
import { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useInvoices } from "includes/hooks";
import logo from "assets/images/logo.png";

const _PROCESS_INVOICES_MUTATION = gql`
	mutation ProcessInvoices($invoiceIds: [String!]!) {
		processInvoices(invoiceIds: $invoiceIds)
	}
`;

const today = new Date();
const lastWeek = new Date();
lastWeek.setDate(today.getDate() - 7);

const todayString = today.toISOString().substr(0, 10);
const lastWeekString = lastWeek.toISOString().substr(0, 10);

export interface Props {}

export const Invoices = ({}: Props) => {
	const onRefresh = () => {
		setSelectedInvoices({});
		refetch(fromDate, toDate);
	};
	const onProcessInvoices = () => {
		setSelectedInvoices({});
		processInvoices({
			variables: {
				invoiceIds: Object.keys(selectedInvoices),
			},
		});
	};
	const onInvoiceSelected = (id: string) => {
		if (selectedInvoices[id]) {
			delete selectedInvoices[id];
		} else {
			selectedInvoices[id] = true;
		}

		setSelectedInvoices({ ...selectedInvoices });
	};
	const onSelectAll = () => {
		if (allSelected) {
			setSelectedInvoices({});
		} else {
			for (const invoice of unprocessedInvoices) {
				selectedInvoices[invoice.myobId] = true;
			}

			setSelectedInvoices({ ...selectedInvoices });
		}
	};
	const onSetTextFilter = (filter: string) => {
		setSelectedInvoices({});
		setTextFilter(filter);
	};
	const onSetStatusFilter = (filter: string) => {
		setSelectedInvoices({});
		setStatusFilter(filter);
	};

	const methods = useForm();
	const [processInvoices] = useProcessInvoicesMutation();

	const [selectedInvoices, setSelectedInvoices] = useState<{
		[k: string]: true;
	}>({});
	const [fromDate, setFromDate] = useState(lastWeekString);
	const [toDate, setToDate] = useState(todayString);
	const [statusFilter, setStatusFilter] = useState("All");
	const [textFilter, setTextFilter] = useState("");
	const { invoices, loading, refetch } = useInvoices(
		lastWeekString,
		todayString
	);

	const filteredInvoices = invoices
		.filter((invoice) => {
			if (statusFilter === "All") {
				return invoice.status !== "Processed";
			}

			return invoice.status === statusFilter;
		})
		.filter((invoice) => {
			return invoice.invoiceNumber.includes(textFilter);
		});

	const tableRows = filteredInvoices.map((invoice) => (
		<TableRow
			key={invoice.myobId}
			selected={!!selectedInvoices[invoice.myobId]}
			onSelect={() => onInvoiceSelected(invoice.myobId)}
			invoice={invoice}
		/>
	));
	const unprocessedInvoices = filteredInvoices.filter(
		(invoice) => invoice.status === "Unprocessed"
	);
	const allSelected =
		unprocessedInvoices.length > 0 &&
		Object.keys(selectedInvoices).length === unprocessedInvoices.length;

	return (
		<FormProvider {...methods}>
			<BaseTemplate>
				<div className="flex justify-between mb-8 items-end">
					<img className="h-16" src={logo} alt="logo" />
					<div>Vanguard EDI 2.0</div>
				</div>
				<TableFilterBar
					onProcess={onProcessInvoices}
					statusFilter={statusFilter}
					onRefresh={onRefresh}
					processEnabled={Object.keys(selectedInvoices).length > 0}
					refreshEnabled={!loading}
					onStatusFilterChange={onSetStatusFilter}
					textFilter={textFilter}
					onTextFilterChange={onSetTextFilter}
					fromDate={fromDate}
					toDate={toDate}
					onFromDateChange={setFromDate}
					onToDateChange={setToDate}
				/>
				<div className="overflow-hidden mt-8 w-full flex flex-col flex-1">
					<TableHeader
						allSelectDisabled={unprocessedInvoices.length === 0}
						allSelected={allSelected}
						onSelectAll={onSelectAll}
					/>
					<div className="pt-8 bg-white flex-1 overflow-auto">
						{loading ? <div className="text-center">loading</div> : tableRows}
					</div>
				</div>
			</BaseTemplate>
		</FormProvider>
	);
};
