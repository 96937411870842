import { Choice, Select } from "components/atoms/Select";
import { ChangeEventHandler } from "react";

export interface Props<T> {
	label: string;
	choices: Choice<T>[];
	value: T;
	onChange: ChangeEventHandler<HTMLSelectElement>;
}

export const SelectWithLabel = <T extends string | number>({
	label,
	choices,
	value,
	onChange,
}: Props<T>) => {
	return (
		<div className="flex flex-col">
			<div className="mb-3 font-bold">{label}</div>
			<Select onChange={onChange} choices={choices} value={value} />
		</div>
	);
};
