import { TextField } from "components/atoms/TextField";

export interface Props {
	label: string;
	name: string;
}

export const TextFieldWithLabel = ({ label, name }: Props) => {
	return (
		<div className="flex flex-col">
			<div className="mb-3 font-bold">{label}</div>
			<TextField name={name} />
		</div>
	);
};
