import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: any;
};



export type CompanyFile = {
  __typename?: 'CompanyFile';
  id: Scalars['String'];
  name: Scalars['String'];
};


export type Invoice = {
  __typename?: 'Invoice';
  id: Scalars['Int'];
  myobId: Scalars['String'];
  invoiceNumber: Scalars['String'];
  date: Scalars['DateTime'];
  name: Scalars['String'];
  postcode: Scalars['String'];
  shippingMethod: Scalars['String'];
  distributionNetwork: Scalars['String'];
  status: Scalars['String'];
  processingSteps: Array<ProcessingStep>;
  invalidFields: Array<InvoiceInvalidField>;
};

export type InvoiceInvalidField = {
  __typename?: 'InvoiceInvalidField';
  field: Scalars['String'];
  value: Scalars['String'];
  limit: Scalars['Int'];
  reason: Scalars['String'];
};

export type InvoiceStatusUpdate = {
  __typename?: 'InvoiceStatusUpdate';
  myobId: Scalars['String'];
  newStatus: Scalars['String'];
};

export type InvoiceStepUpdate = {
  __typename?: 'InvoiceStepUpdate';
  myobId: Scalars['String'];
  step: Scalars['String'];
  newStatus: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  loginCompanyFile: Scalars['Boolean'];
  processInvoices: Scalars['Boolean'];
  resolveInvoice: Scalars['Boolean'];
  clearInvoice: Scalars['Boolean'];
};


export type MutationLoginCompanyFileArgs = {
  cfId?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};


export type MutationProcessInvoicesArgs = {
  invoiceIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationResolveInvoiceArgs = {
  invoiceId?: Maybe<Scalars['String']>;
};


export type MutationClearInvoiceArgs = {
  invoiceId?: Maybe<Scalars['String']>;
};

export type ProcessingStep = {
  __typename?: 'ProcessingStep';
  name: Scalars['String'];
  status: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  companyFiles: Array<CompanyFile>;
  invoices: Array<Invoice>;
};


export type QueryInvoicesArgs = {
  fromDate: Scalars['String'];
  toDate: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  invoiceStatusUpdated: InvoiceStatusUpdate;
  invoiceStepUpdated: InvoiceStepUpdate;
};

export type ResolveInvoiceMutationVariables = Exact<{
  invoiceId: Scalars['String'];
}>;


export type ResolveInvoiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resolveInvoice'>
);

export type ClearInvoiceMutationVariables = Exact<{
  invoiceId: Scalars['String'];
}>;


export type ClearInvoiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'clearInvoice'>
);

export type ProcessInvoicesMutationVariables = Exact<{
  invoiceIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type ProcessInvoicesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'processInvoices'>
);

export type GetCompanyFilesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompanyFilesQuery = (
  { __typename?: 'Query' }
  & { companyFiles: Array<(
    { __typename?: 'CompanyFile' }
    & Pick<CompanyFile, 'id' | 'name'>
  )> }
);

export type LoginCompanyFileMutationVariables = Exact<{
  cfId: Scalars['String'];
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginCompanyFileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'loginCompanyFile'>
);

export type GetInvoicesQueryVariables = Exact<{
  fromDate: Scalars['String'];
  toDate: Scalars['String'];
}>;


export type GetInvoicesQuery = (
  { __typename?: 'Query' }
  & { invoices: Array<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'id' | 'myobId' | 'invoiceNumber' | 'date' | 'name' | 'postcode' | 'shippingMethod' | 'distributionNetwork' | 'status'>
    & { processingSteps: Array<(
      { __typename?: 'ProcessingStep' }
      & Pick<ProcessingStep, 'name' | 'status'>
    )>, invalidFields: Array<(
      { __typename?: 'InvoiceInvalidField' }
      & Pick<InvoiceInvalidField, 'field' | 'value' | 'limit' | 'reason'>
    )> }
  )> }
);

export type InvoiceStatusUpdatesSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type InvoiceStatusUpdatesSubscription = (
  { __typename?: 'Subscription' }
  & { invoiceStatusUpdated: (
    { __typename?: 'InvoiceStatusUpdate' }
    & Pick<InvoiceStatusUpdate, 'myobId' | 'newStatus'>
  ) }
);

export type InvoiceStepUpdatesSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type InvoiceStepUpdatesSubscription = (
  { __typename?: 'Subscription' }
  & { invoiceStepUpdated: (
    { __typename?: 'InvoiceStepUpdate' }
    & Pick<InvoiceStepUpdate, 'myobId' | 'step' | 'newStatus'>
  ) }
);


export const ResolveInvoiceDocument = gql`
    mutation ResolveInvoice($invoiceId: String!) {
  resolveInvoice(invoiceId: $invoiceId)
}
    `;
export type ResolveInvoiceMutationFn = Apollo.MutationFunction<ResolveInvoiceMutation, ResolveInvoiceMutationVariables>;

/**
 * __useResolveInvoiceMutation__
 *
 * To run a mutation, you first call `useResolveInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResolveInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resolveInvoiceMutation, { data, loading, error }] = useResolveInvoiceMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useResolveInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<ResolveInvoiceMutation, ResolveInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResolveInvoiceMutation, ResolveInvoiceMutationVariables>(ResolveInvoiceDocument, options);
      }
export type ResolveInvoiceMutationHookResult = ReturnType<typeof useResolveInvoiceMutation>;
export type ResolveInvoiceMutationResult = Apollo.MutationResult<ResolveInvoiceMutation>;
export type ResolveInvoiceMutationOptions = Apollo.BaseMutationOptions<ResolveInvoiceMutation, ResolveInvoiceMutationVariables>;
export const ClearInvoiceDocument = gql`
    mutation ClearInvoice($invoiceId: String!) {
  clearInvoice(invoiceId: $invoiceId)
}
    `;
export type ClearInvoiceMutationFn = Apollo.MutationFunction<ClearInvoiceMutation, ClearInvoiceMutationVariables>;

/**
 * __useClearInvoiceMutation__
 *
 * To run a mutation, you first call `useClearInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearInvoiceMutation, { data, loading, error }] = useClearInvoiceMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useClearInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<ClearInvoiceMutation, ClearInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClearInvoiceMutation, ClearInvoiceMutationVariables>(ClearInvoiceDocument, options);
      }
export type ClearInvoiceMutationHookResult = ReturnType<typeof useClearInvoiceMutation>;
export type ClearInvoiceMutationResult = Apollo.MutationResult<ClearInvoiceMutation>;
export type ClearInvoiceMutationOptions = Apollo.BaseMutationOptions<ClearInvoiceMutation, ClearInvoiceMutationVariables>;
export const ProcessInvoicesDocument = gql`
    mutation ProcessInvoices($invoiceIds: [String!]!) {
  processInvoices(invoiceIds: $invoiceIds)
}
    `;
export type ProcessInvoicesMutationFn = Apollo.MutationFunction<ProcessInvoicesMutation, ProcessInvoicesMutationVariables>;

/**
 * __useProcessInvoicesMutation__
 *
 * To run a mutation, you first call `useProcessInvoicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessInvoicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processInvoicesMutation, { data, loading, error }] = useProcessInvoicesMutation({
 *   variables: {
 *      invoiceIds: // value for 'invoiceIds'
 *   },
 * });
 */
export function useProcessInvoicesMutation(baseOptions?: Apollo.MutationHookOptions<ProcessInvoicesMutation, ProcessInvoicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProcessInvoicesMutation, ProcessInvoicesMutationVariables>(ProcessInvoicesDocument, options);
      }
export type ProcessInvoicesMutationHookResult = ReturnType<typeof useProcessInvoicesMutation>;
export type ProcessInvoicesMutationResult = Apollo.MutationResult<ProcessInvoicesMutation>;
export type ProcessInvoicesMutationOptions = Apollo.BaseMutationOptions<ProcessInvoicesMutation, ProcessInvoicesMutationVariables>;
export const GetCompanyFilesDocument = gql`
    query GetCompanyFiles {
  companyFiles {
    id
    name
  }
}
    `;

/**
 * __useGetCompanyFilesQuery__
 *
 * To run a query within a React component, call `useGetCompanyFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyFilesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyFilesQuery(baseOptions?: Apollo.QueryHookOptions<GetCompanyFilesQuery, GetCompanyFilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyFilesQuery, GetCompanyFilesQueryVariables>(GetCompanyFilesDocument, options);
      }
export function useGetCompanyFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyFilesQuery, GetCompanyFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyFilesQuery, GetCompanyFilesQueryVariables>(GetCompanyFilesDocument, options);
        }
export type GetCompanyFilesQueryHookResult = ReturnType<typeof useGetCompanyFilesQuery>;
export type GetCompanyFilesLazyQueryHookResult = ReturnType<typeof useGetCompanyFilesLazyQuery>;
export type GetCompanyFilesQueryResult = Apollo.QueryResult<GetCompanyFilesQuery, GetCompanyFilesQueryVariables>;
export const LoginCompanyFileDocument = gql`
    mutation LoginCompanyFile($cfId: String!, $username: String!, $password: String!) {
  loginCompanyFile(cfId: $cfId, username: $username, password: $password)
}
    `;
export type LoginCompanyFileMutationFn = Apollo.MutationFunction<LoginCompanyFileMutation, LoginCompanyFileMutationVariables>;

/**
 * __useLoginCompanyFileMutation__
 *
 * To run a mutation, you first call `useLoginCompanyFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginCompanyFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginCompanyFileMutation, { data, loading, error }] = useLoginCompanyFileMutation({
 *   variables: {
 *      cfId: // value for 'cfId'
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginCompanyFileMutation(baseOptions?: Apollo.MutationHookOptions<LoginCompanyFileMutation, LoginCompanyFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginCompanyFileMutation, LoginCompanyFileMutationVariables>(LoginCompanyFileDocument, options);
      }
export type LoginCompanyFileMutationHookResult = ReturnType<typeof useLoginCompanyFileMutation>;
export type LoginCompanyFileMutationResult = Apollo.MutationResult<LoginCompanyFileMutation>;
export type LoginCompanyFileMutationOptions = Apollo.BaseMutationOptions<LoginCompanyFileMutation, LoginCompanyFileMutationVariables>;
export const GetInvoicesDocument = gql`
    query GetInvoices($fromDate: String!, $toDate: String!) {
  invoices(fromDate: $fromDate, toDate: $toDate) {
    id
    myobId
    invoiceNumber
    date
    name
    postcode
    shippingMethod
    distributionNetwork
    status
    processingSteps {
      name
      status
    }
    invalidFields {
      field
      value
      limit
      reason
    }
  }
}
    `;

/**
 * __useGetInvoicesQuery__
 *
 * To run a query within a React component, call `useGetInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicesQuery({
 *   variables: {
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *   },
 * });
 */
export function useGetInvoicesQuery(baseOptions: Apollo.QueryHookOptions<GetInvoicesQuery, GetInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoicesQuery, GetInvoicesQueryVariables>(GetInvoicesDocument, options);
      }
export function useGetInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoicesQuery, GetInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoicesQuery, GetInvoicesQueryVariables>(GetInvoicesDocument, options);
        }
export type GetInvoicesQueryHookResult = ReturnType<typeof useGetInvoicesQuery>;
export type GetInvoicesLazyQueryHookResult = ReturnType<typeof useGetInvoicesLazyQuery>;
export type GetInvoicesQueryResult = Apollo.QueryResult<GetInvoicesQuery, GetInvoicesQueryVariables>;
export const InvoiceStatusUpdatesDocument = gql`
    subscription InvoiceStatusUpdates {
  invoiceStatusUpdated {
    myobId
    newStatus
  }
}
    `;

/**
 * __useInvoiceStatusUpdatesSubscription__
 *
 * To run a query within a React component, call `useInvoiceStatusUpdatesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceStatusUpdatesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceStatusUpdatesSubscription({
 *   variables: {
 *   },
 * });
 */
export function useInvoiceStatusUpdatesSubscription(baseOptions?: Apollo.SubscriptionHookOptions<InvoiceStatusUpdatesSubscription, InvoiceStatusUpdatesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<InvoiceStatusUpdatesSubscription, InvoiceStatusUpdatesSubscriptionVariables>(InvoiceStatusUpdatesDocument, options);
      }
export type InvoiceStatusUpdatesSubscriptionHookResult = ReturnType<typeof useInvoiceStatusUpdatesSubscription>;
export type InvoiceStatusUpdatesSubscriptionResult = Apollo.SubscriptionResult<InvoiceStatusUpdatesSubscription>;
export const InvoiceStepUpdatesDocument = gql`
    subscription InvoiceStepUpdates {
  invoiceStepUpdated {
    myobId
    step
    newStatus
  }
}
    `;

/**
 * __useInvoiceStepUpdatesSubscription__
 *
 * To run a query within a React component, call `useInvoiceStepUpdatesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceStepUpdatesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceStepUpdatesSubscription({
 *   variables: {
 *   },
 * });
 */
export function useInvoiceStepUpdatesSubscription(baseOptions?: Apollo.SubscriptionHookOptions<InvoiceStepUpdatesSubscription, InvoiceStepUpdatesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<InvoiceStepUpdatesSubscription, InvoiceStepUpdatesSubscriptionVariables>(InvoiceStepUpdatesDocument, options);
      }
export type InvoiceStepUpdatesSubscriptionHookResult = ReturnType<typeof useInvoiceStepUpdatesSubscription>;
export type InvoiceStepUpdatesSubscriptionResult = Apollo.SubscriptionResult<InvoiceStepUpdatesSubscription>;