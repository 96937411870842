import { ReactSVG } from "react-svg";
import search from "assets/icons/search.svg";

export interface Props {
	value: string;
	classNames?: string;
	onChange(search: string): void;
}

export const Search = ({ classNames, value, onChange }: Props) => {
	return (
		<div
			className={`border border-cream-600 bg-white flex py-3.5 px-5 ${classNames} focus-within:border-cream-900`}
		>
			<ReactSVG src={search} className="mr-2" />
			<input
				className="h-full w-full outline-none"
				value={value}
				onChange={(event) => onChange(event.target.value)}
			/>
		</div>
	);
};
