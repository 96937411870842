/* eslint-disable jsx-a11y/label-has-associated-control */
import { CheckBox } from "components/atoms/CheckBox";

export interface Props {
	allSelected: boolean;
	allSelectDisabled: boolean;
	onSelectAll(): void;
}

export const TableHeader = ({
	allSelected,
	allSelectDisabled,
	onSelectAll,
}: Props) => {
	return (
		<div className="grid grid-cols-table bg-blue h-14 items-center px-8">
			<div aria-label="copy" />
			<div>
				<label htmlFor="selectAll" className="flex">
					<CheckBox
						checked={allSelected}
						onChange={onSelectAll}
						disabled={allSelectDisabled}
					/>
					<span className="ml-4 text-white text-sm">Invoices</span>
				</label>
			</div>
			<div className="text-white text-sm">Date</div>
			<div className="text-white text-sm">Name</div>
			<div className="text-white text-sm">Postcode</div>
			<div className="text-white text-sm">Shipping Method</div>
			<div className="text-center text-white text-sm">Status</div>
			<div className="text-white text-sm" aria-label="expand" />
		</div>
	);
};
