import { ChangeEventHandler } from "react";

export interface Choice<T> {
	value: T;
	label: string;
}

export interface Props<T> {
	choices: Choice<T>[];
	value: T;
	classNames?: string;
	onChange: ChangeEventHandler<HTMLSelectElement>;
}

export const Select = <T extends string | number>({
	choices,
	value,
	classNames,
	onChange,
}: Props<T>) => {
	const choicesDropdown = choices.map((choice) => (
		<option key={choice.value} value={choice.value}>
			{choice.label}
		</option>
	));

	return (
		<div className={`px-5 py-3 border border-cream-900 bg-white ${classNames}`}>
			<select
				className="bg-transparent w-full"
				value={value}
				onChange={onChange}
			>
				{choicesDropdown}
			</select>
		</div>
	);
};
