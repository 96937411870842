import { gql, NetworkStatus } from "@apollo/client";
import {
	useInvoiceStatusUpdatesSubscription,
	Invoice,
	useGetInvoicesQuery,
	useInvoiceStepUpdatesSubscription,
} from "graphql/types";
import { useState, useEffect } from "react";

const _GET_INVOICES_QUERY = gql`
	query GetInvoices($fromDate: String!, $toDate: String!) {
		invoices(fromDate: $fromDate, toDate: $toDate) {
			id
			myobId
			invoiceNumber
			date
			name
			postcode
			shippingMethod
			distributionNetwork
			status
			processingSteps {
				name
				status
			}
			invalidFields {
				field
				value
				limit
				reason
			}
		}
	}
`;

const _INVOICE_UPDATE_SUBSCRIPTION = gql`
	subscription InvoiceStatusUpdates {
		invoiceStatusUpdated {
			myobId
			newStatus
		}
	}
`;

const _INVOICE_STEP_UPDATE_SUBSCRIPTION = gql`
	subscription InvoiceStepUpdates {
		invoiceStepUpdated {
			myobId
			step
			newStatus
		}
	}
`;

export const useInvoices = (from: string, to: string) => {
	const resetInvoicesObject = (invoices: Invoice[]) => {
		const invoicesObject: { [k: string]: Invoice } = {};
		for (const invoice of invoices) {
			invoicesObject[invoice.myobId] = invoice;
		}

		setInvoicesObject(invoicesObject);
	};
	const onRefetch = async (fromDate: string, toDate: string) => {
		const result = await invoicesQuery.refetch({
			fromDate,
			toDate,
		});
		resetInvoicesObject(result.data.invoices);
	};

	const invoicesQuery = useGetInvoicesQuery({
		notifyOnNetworkStatusChange: true,
		variables: {
			fromDate: from,
			toDate: to,
		},
	});
	useInvoiceStatusUpdatesSubscription({
		onSubscriptionData(subscriptionData) {
			const update =
				subscriptionData.subscriptionData.data?.invoiceStatusUpdated;
			if (!update) {
				return;
			}

			const { myobId, newStatus } = update;
			const invoice = invoicesObject[myobId];
			if (!invoice) {
				return;
			}

			setInvoicesObject({
				...invoicesObject,
				[myobId]: {
					...invoice,
					status: newStatus,
				},
			});
		},
	});
	useInvoiceStepUpdatesSubscription({
		onSubscriptionData(subscriptionData) {
			const update = subscriptionData.subscriptionData.data?.invoiceStepUpdated;
			if (!update) {
				return;
			}

			const { myobId, newStatus, step } = update;
			for (const invoice of Object.values(invoicesObject)) {
				if (invoice.myobId === myobId) {
					invoicesObject[myobId] = {
						...invoicesObject[myobId],
						processingSteps: invoicesObject[myobId].processingSteps.map(
							(processingStep) => {
								if (processingStep.name !== step) {
									return processingStep;
								}

								return {
									...processingStep,
									status: newStatus,
								};
							}
						),
					};
					break;
				}
			}

			setInvoicesObject({ ...invoicesObject });
		},
	});

	const [invoicesObject, setInvoicesObject] = useState<{
		[k: string]: Invoice;
	}>({});

	const queriedInvoices = invoicesQuery.data?.invoices;
	useEffect(() => {
		if (queriedInvoices) {
			resetInvoicesObject(queriedInvoices);
		}
	}, [queriedInvoices]);

	const invoices = Object.values(invoicesObject);

	const loading =
		invoicesQuery.loading ||
		invoicesQuery.networkStatus === NetworkStatus.refetch;

	return {
		loading,
		invoices,
		refetch: onRefetch,
	};
};
