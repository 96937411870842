import { useFormContext } from "react-hook-form";

export interface Props {
	name: string;
}

export const TextField = ({ name }: Props) => {
	const { register } = useFormContext();
	return (
		<input
			className="bg-white border-cream-600 border py-3 px-5"
			{...register(name)}
		/>
	);
};
