import { ReactNode } from "react";

export interface Props {
	children: ReactNode;
}

export const BaseTemplate = ({ children }: Props) => {
	return (
		<div className="bg-cream-200 flex justify-center h-screen py-16">
			<div className="w-300 flex flex-col">{children}</div>
		</div>
	);
};
