import verticalDots from "assets/icons/vertical-dots.svg";
import checkMark from "assets/icons/check-mark.svg";
import processing from "assets/icons/processing.svg";
import redX from "assets/icons/red-x.svg";
import { ReactSVG } from "react-svg";

export interface Props {
	text: string;
	status: string;
}

export const ModalInvoiceStep = ({ text, status }: Props) => {
	let svgSrc = processing;
	let color = "bg-black bg-opacity-5";

	switch (status) {
		case "Success":
			svgSrc = checkMark;
			color = "bg-success-light";
			break;
		case "Error":
			svgSrc = redX;
			color = "bg-error-light";
			break;
	}

	return (
		<div className="flex flex-col items-center w-full">
			<div className={`w-full h-10 flex items-center ${color}`}>
				<ReactSVG src={svgSrc} className="px-4" />
				{text}
			</div>
			<ReactSVG src={verticalDots} />
		</div>
	);
};
