import { ReactElement } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Index from "components/pages/Index";
import { CompanyFiles } from "components/pages/CompanyFiles";
import {
	ApolloClient,
	ApolloProvider,
	HttpLink,
	InMemoryCache,
	split,
} from "@apollo/client";
import { Invoices } from "components/pages/Invoices";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";

const baseHost = process.env.REACT_APP_BASE_HOST;

const httpLink = new HttpLink({
	uri: `https://${baseHost}/graphql`,
});
const wsLink = new WebSocketLink({
	uri: `wss://${baseHost}/graphql`,
	options: {
		reconnect: true,
	},
});

const splitLink = split(
	({ query }) => {
		const definition = getMainDefinition(query);
		return (
			definition.kind === "OperationDefinition" &&
			definition.operation === "subscription"
		);
	},
	wsLink,
	httpLink
);

const client = new ApolloClient({
	cache: new InMemoryCache(),
	link: splitLink,
});

const App = (): ReactElement => {
	return (
		<ApolloProvider client={client}>
			<Router>
				<Switch>
					<Route path="/company-files">
						<CompanyFiles />
					</Route>
					<Route path="/invoices">
						<Invoices />
					</Route>
					<Route path="/">
						<Index />
					</Route>
				</Switch>
			</Router>
		</ApolloProvider>
	);
};

export default App;
